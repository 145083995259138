import React from "react";
import { Button, Container } from "reactstrap";

// core components

function GalleryPageHeader(props) {
  let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });
  return (
    <>
      <div className="page-header gallery page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url(${(props.photoCover && props.photoCover) || "../public/book.png"})`
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
            {/* <h1 className="title">Gallery </h1> */}
          </Container>
        </div>
      </div>
    </>
  );
}

export default GalleryPageHeader;
